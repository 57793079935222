import React, { useState } from 'react'
import styles from './Textarea.module.sass'

const Textarea = ({ placeholder, width, height, value, setValue }) => {
  const [activeText, setActiveText] = useState(true);

  const handleFocusInput = () => setActiveText(false);

  const handleBlurInput = () => {
    setActiveText(true);
  };

  const handleChangeInput = (e) => {
    setValue(e.target.value);
  };

  const renderPlaceholder = () => {
    if (value || !activeText) return null;

    return (
      <p className={ styles.placeholder }>{ placeholder }</p>
    )
  }
  return (
    <div className={styles.root}>
      <textarea
        className={styles.input}
        onFocus={ handleFocusInput }
        onBlur={ handleBlurInput }
        onChange={ handleChangeInput }
        style={{ width, height }}
        value={ value }
      >
      </textarea>
      { renderPlaceholder() }
    </div>
  )
}

export default Textarea
