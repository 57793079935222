import carersImg from '../../images/about-us/3.svg'
import docsImg from '../../images/about-us/4.svg'
import hqDubaiImg from '../../images/about-us/1.svg'
import referral from '../../images/about-us/2.svg'
import leftTopPolygon from '../../images/about-us/leftTopPoligon.png'
import leftBottomPolygon from '../../images/about-us/leftBottomPoligon.png'
import rightTopPolygon from '../../images/about-us/RightTopPoligon.png'
import AboutUsTextButton from '../../images/about-us/AboutUsButtonText.png';

import styles from "./index.module.css";

function AboutUs() {
  return (
    <>
      <div className={styles.aboutUsWrapper}>
        <div className={styles.aboutUsContent}>
          <div className={styles.contentContainer}>
            <div className={styles.icon}>
              <img src={hqDubaiImg} alt="company"/>
            </div>
            <span className={styles.sectionName}>Company</span>
            <span className={styles.sectionText}>HQ Dubai</span>
          </div>

          <div className={styles.contentContainer}>
            <div className={styles.icon}>
              <img src={referral} alt="Referral"/>
            </div>
            <span className={styles.sectionName}>Referral</span>
            <span className={styles.sectionText}>Coming soon</span>
          </div>

          <div className={styles.contentContainer}>
            <div className={styles.icon}>
              <img src={carersImg} alt="Careers"/>
            </div>
            <span className={styles.sectionName}>Careers</span>
            <span className={styles.sectionText}>See the list of our open positions</span>
          </div>

          <div className={styles.contentContainer}>
            <div className={styles.icon}>
              <img src={docsImg} alt="company"/>
            </div>
            <span className={styles.sectionName}>Docs</span>
            <span className={styles.sectionText}>WhitePaper <br/>Litepaper</span>
          </div>
        </div>
        <div className={styles.aboutUsButtonContainer}>
          <div className={styles.aboutUsButton}>
            <img src={AboutUsTextButton} alt="button"/>
          </div>
        </div>
        <img src={ leftTopPolygon }
             className={styles.left}
             height='206px'
             width='214px'
        />
        <img
          src={ leftBottomPolygon }
          className={styles.leftBottom}
          height='185px'
          width='169px'
        />
        <img
          src={ rightTopPolygon }
          className={styles.rightPolygon}
          height='180px'
          width='190px'
        />
      </div>
      <div className={styles.rectangleBlur}/>
    </>
  );
}

export default AboutUs;
