import {forwardRef, useEffect, useRef, useState} from "react";

import fullRing from "../../../images/solutions/fullRing.png";
import rightRound from "../../../images/solutions/subtract-right.png";

import styles from './index.module.css';
import mobileStyles from './mobile.module.css';

export function useOnScreen(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    const callback = ([entry]) => {
      setIsOnScreen(entry.isIntersecting)
    };
    observerRef.current = new IntersectionObserver(callback, {
      rootMargin: "0px",
      threshold: 1,
    })
  }, []);

  useEffect(() => {
    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}


const AnimatedRing = forwardRef((props, ref) => {
  const [clickedButton, setClickedButton] = useState(2);
  const imageRef = useRef(null);
  const { onClickButton, pressedLeft } = props;

  const [animatedRing, setAnimatedRing] = useState(false);
  const isOpen = useOnScreen(ref);

  const startRingAnimationOnClick = () => {
    setAnimatedRing(true);
    imageRef.current.className = styles.animatedFullRing;
  };

  useEffect(() => {
    if (isOpen && window.innerWidth > 1239 ) startRingAnimationOnClick();
  } ,[isOpen])


  const handleClickAnimate = () => {}


  const ringButton = (number, alignFlex='flex-start') => (
    <div
      style={{ paddingBottom: '15px', display: 'flex', justifyContent: `${alignFlex}` }}
      onClick={() => onClickButton(number === 1)}
    >
      <div className={styles.ringButton} onClick={handleClickAnimate} >
        {number}
      </div>
    </div>
  )

  const contentToFirstRing = (x, y) => {
    if(!animatedRing || window.innerWidth < 1239) return null;

    let headerAnimation = [ styles.contentForRingHeader ];
    let borderBottomAnimation = [ styles.borderBottom ];
    let contentTextAnimation = [ styles.contentForRingText ];

    if (pressedLeft && window.innerWidth > 1239) {
      headerAnimation.push(styles.leftShacking);
      borderBottomAnimation.push(styles.leftShacking);
      contentTextAnimation.push(styles.visible);
    }

    return (
      <div className={styles.contentForFirstRing} style={{ top:`${x}`, left:`${y}` }} >
        <div className={ styles.contentForFirstRingText}>
          <div className={ headerAnimation.join(' ') }>Cyber Will</div>
          <div className={ contentTextAnimation.join(' ') }>
            Cyber will provides a solution to the problem of transferring cryptocurrency wallets and other valuable files to their heirs or others in case the owner becomes unable to manage them
          </div>
        </div>
        <div styles={ styles.firstRing}>
          {ringButton(1, 'flex-end')}
        </div>
        <div className={ borderBottomAnimation.join(' ') } />
      </div>
    )
  }
  const contentToSecondRing = (x, y) => {
    if (!animatedRing || window.innerWidth < 1239) return null;

    let headerAnimation = [ styles.contentForRingHeader ];
    let borderBottomAnimation = [ styles.borderBottom ];
    let contentTextAnimation = [ styles.contentForRingText ];

    if (!pressedLeft && pressedLeft !== null && window.innerWidth > 1239) {
      headerAnimation.push(styles.rightShacking);
      borderBottomAnimation.push(styles.rightShacking);
      contentTextAnimation.push(styles.visible);
    }

    return (
      <div className={styles.contentForSecondRing} style={{ top:`${x}`, left:`${y}` }} >
        {ringButton(2)}
        <div className={ borderBottomAnimation.join(' ') } style={{ top: '25%' }}/>
        <div className={ headerAnimation.join(' ') }>Cyber Will</div>
        <div className={ contentTextAnimation.join(' ')}>
          Creating a secure online data storage product can solve this problem by providing users
          with convenience and security of storing their valuable data online.
        </div>
      </div>
    )
  }

  const contentToRingsMobile = () => {
    if (window.innerWidth > 1239) return null;

    return (
      <div className={ mobileStyles.ringsWrapper}>
        <div className={ clickedButton === 1 ? mobileStyles.animatedHeaderTop : mobileStyles.animatedHeaderBottom }>
          <div>Cyber Will</div>
          <div className={ mobileStyles.border} />
        </div>
        <img
          src={rightRound}
          style={{ position: 'absolute' }}
          className={ clickedButton === 1 ? mobileStyles.halfRingTop : mobileStyles.halfRingBottom }
        />

        <div
          style={{ position: 'absolute', top: '-15%'}}
          onClick={() => setClickedButton(1)}
          className={ clickedButton === 1 ? mobileStyles.invisible : mobileStyles.visible }
        >
          {ringButton(1)}
        </div>
        <div
          style={{ position: 'absolute', top: '-20%', color: '#737373', textAlign: 'center' }}
          className={ clickedButton !== 1 ? mobileStyles.invisible : mobileStyles.visible }
        >
          Cyber will provides a solution to the problem of transferring cryptocurrency wallets and other valuable files to their heirs or others in case the owner becomes unable to manage them
        </div>

        <div
          style={{ position: 'absolute', top: '95%', zIndex: '1000'}}
          onClick={() => setClickedButton(2)}
          className={ clickedButton === 2 ? mobileStyles.invisible : mobileStyles.visible }
        >
          {ringButton(2)}
        </div>
        <div
          style={{ position: 'absolute', top: '105%', color: '#737373', textAlign: 'center' }}
          className={ clickedButton !== 2 ? mobileStyles.invisible : mobileStyles.visible}
        >
          Creating a secure online data storage product can solve this problem by providing users
          with convenience and security of storing their valuable data online.
        </div>
      </div>
    )
  }

  return (
    <>
      <img className={styles.fullRingMobile} src={fullRing} ref={ imageRef }/>
      {contentToRingsMobile()}
      {contentToFirstRing()}
      {contentToSecondRing()}
    </>
  );
});

export { AnimatedRing };
