import {useState} from "react";

import styles from './index.module.css';

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenMenu = () => {
    setIsOpen(!isOpen);
  }
  const menuButton = () => {

    return (
      <div className={styles.button} onClick={ handleOpenMenu }>
        <div className={isOpen ? styles.dashContainerClose : styles.dashContainer}>
          <div className={ isOpen ? styles.topDashClose : styles.topDash} />
          <div className={ isOpen ? styles.middleDashClose : styles.middleDash} />
          <div className={ isOpen ? styles.bottomDashClose : styles.bottomDash} />
        </div>
      </div>
    )

  }
  const itemList = () => {

    return(
      <div className={styles.menuList} style={{ opacity: `${ isOpen ? 1 : 0 }`, visibility: `${ isOpen ? 'visible' : 'hidden'}`}}>
        <div className={ styles.item } onClick={handleOpenMenu}><a href="#AboutUs">About Us</a></div>
        <div className={ styles.item } onClick={handleOpenMenu}><a href="#Solution">Solution</a></div>
        <div className={ styles.item } onClick={handleOpenMenu}><a href="#Learn">Learn</a></div>
        <div className={ styles.item } onClick={handleOpenMenu}><a href="#Team">Team</a></div>
        <div className={ styles.item } onClick={handleOpenMenu}><a href="#Contacts">Contacts</a></div>
        <div className={ styles.item } onClick={handleOpenMenu}><a href="#Pricing">Pricing</a></div>
      </div>
    );
  }
  const desktopMenu = () => {
    if (window.innerWidth < 1239 ) return null


    return (
      <>
        { isOpen ? <div className={ styles.rightRectangle } /> : null }
        <div className={styles.menuWrapper}>
          {menuButton()}
          {itemList()}
        </div>
      </>
    );
  }

  const mobileMenu = () => {
    if (window.innerWidth > 1239 ) return null


    return (
      <div className={ isOpen ? styles.menuWrapperMobile : styles.menuWrapperMobileOff}>
        {itemList()}
        {menuButton()}
      </div>
    );
  }

  return (
   <>
     {desktopMenu()}
     {mobileMenu()}
   </>
  );
};

export { BurgerMenu };
