import cyberLogo from '../../images/main/cyberLogo.png';
import ring from '../../images/main/ring.png';
import bigPolygon from '../../images/main/bigPolygon.png';
import smallPolygon from '../../images/main/middlePolygon.png';

import GetButton from './ButtonItem';
import { HoveredPolygons } from "./HoveredPolygons";
import { BurgerMenu } from './BurgerMenu';
import Figure from '../Figure';

import styles from './index.module.css';

const Main = ({ setPopup }) => {
  return(
    <div className={styles.mainWrapper}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src={cyberLogo} alt="cyberlogo"/>
        </div>
        <div className={styles.menuButtons}>
          <button>Log In</button>
          <button>Sign In</button>
        </div>
      </div>
      <BurgerMenu />

      <HoveredPolygons />
      <div
        style={{
          width: '180px',
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          top: '70%',
          left: '50%',
          transform: 'translateX(-50%)'
      }}
      >
        <GetButton setPopup={ setPopup }/>
      </div>
      <div className={styles.ring}>
        <img src={ring} alt="ring"/>
      </div>
      <div className={styles.container}>
        <div className={styles.slogan}>
          <div className={ styles.sloganHeader}>Platform for secure storage and inheritance of digital assets</div>
          <div className={ styles.sloganText}>Our Mission is to guarantee a secure transfer of your wealth to the heirs</div>
        </div>
      </div>
      <Figure
        width='160px'
        height='150px'
        coords={{
          top: '150px',
          left: '60%',
          transform: 'rotate(0deg)'
        }}
        cn={ styles.bigFigure }
        figure={ bigPolygon }
      />
      <Figure
        width='120px'
        height='110px'
        coords={{
          top: '12%',
          left: '25%',
          transform: 'rotate(0deg)'
        }}
        cn={ styles.smallFigure }
        figure={ smallPolygon  }
      />
      {/* <img src={bigPolygon} alt="polygon" className={styles.bigPolygon}/> */}
      {/* <img src={smallPolygon} alt="polygon" className={styles.smallPolygon}/> */}
    </div>
  )
};

export default Main;
