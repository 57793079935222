import React from 'react'
import styles from './Figure.module.sass'

const Figure = ({ width, height, coords, cn, figure }) => {
  const stylesRoot = {
    width,
    height,
    ...coords,
  }

  return (
    <div className={ `${styles.root} ${cn}` } style={ stylesRoot }> 
      <div
        className={styles.figure}
        style={{ backgroundImage: `url(${figure})` }}
      ></div>
      <div className={styles.light}></div>
    </div>
  )
}

export default Figure
