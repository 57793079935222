import styles from './index.module.css'
import leftRound from "../../../images/solutions/substract-left.png";
import rightRound from "../../../images/solutions/subtract-right.png";

const FlyingRings = ({ pressedLeft }) => {
  if (window.innerWidth < 1239) return null;

  let rightRoundAnimation = styles.rightRound;
  let parentAnimation = styles.flyingRingsContainer;

  if (pressedLeft) {
    parentAnimation = styles.leftRoundAnimMoveLeft;
    rightRoundAnimation = styles.rightRoundInvisible;
  } else  if (!pressedLeft && pressedLeft !== null){
    parentAnimation = styles.rightRoundAnimMoveRight;
    rightRoundAnimation = styles.rightRoundInvisible;
  }


  return(
    <div className={parentAnimation}>
      <img src={leftRound} className={styles.leftRound}/>
      <img src={rightRound} className={rightRoundAnimation}/>
    </div>
  );
}

export { FlyingRings };
