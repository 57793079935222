import {useEffect, useState} from "react";
import miniPolygons from '../../../images/main/middlePolygon.png';

import styles from './index.module.css';
// 24*9
const HoveredPolygons = () => {
  const [polygons, setPolygons] = useState([]);

  useEffect(() => {

  }, []);
  const polygonsHtml = () => {
    const polygons = []
    for (let i = 0; i < 232; i+= 1) {
      polygons.push( <img key={i} src={miniPolygons} className={styles.polygons}/>)
    }

    return(<> {polygons} </>)
  }

  return(
    <div className={ styles.polygonsWrapper}>
      { polygonsHtml() }
    </div>
  )
};

export { HoveredPolygons };
