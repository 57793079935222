import { useRef, useState } from "react";

import logo from '../../images/solutions/logo.png';

import { AnimatedRing } from "./AnimatedRing";
import { FlyingRings } from "./FlyingRings";

import styles from "./index.module.css";

function Solution() {
  const ref = useRef(null);
  const [buttonClicked, setButtonClicked] = useState(null);


  return (
    <>
    <div className={styles.solutionWrapper} ref={ ref }>
        <div className={styles.logoContainer}>
          <img src={logo} className={styles.logo}/>
        </div>
        <div className={styles.textContainer}>
          Thanks to encrypt smart contracts,
          the Cyber Will platform will give access to information
          to the heirs when the conditions prescribed in the hereditary cell are reached.
        </div>
      <AnimatedRing ref={ref} onClickButton={ setButtonClicked } pressedLeft={ buttonClicked }/>
      <FlyingRings pressedLeft={ buttonClicked }/>
    </div>
      <div className={styles.whitBlock}></div>
    </>
  );
}

export default Solution;

